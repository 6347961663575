import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import styles from "../../assets/styles/ServicesSection.module.css";
import axios from "axios";
import { Link } from "react-router-dom";

const propTypes = {};

const defaultProps = {};

const ServicesSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const [services, setServices] = useState([]);
  const cardRefs = useRef([]);

  const getServices = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/services/get`, {
        params: {},
      })
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    const adjustCardHeights = () => {
      cardRefs.current.forEach((card) => {
        if (card) card.style.height = "auto";
      });

      const heights = cardRefs.current.map((card) => card?.offsetHeight || 0);
      const maxHeight = Math.max(...heights);

      cardRefs.current.forEach((card) => {
        if (card) card.style.height = `${maxHeight}px`;
      });
    };

    if (services.length > 0) {
      adjustCardHeights();
    }

    window.addEventListener("resize", adjustCardHeights);
    return () => window.removeEventListener("resize", adjustCardHeights);
  }, [services]);

  return (
    <section
      className={`${styles.whiteSection} ${styles.full_width} ${styles.clearfix} ${styles.coursesSection}`}
      id="Services"
    >
      <div className={styles.container}>
        <div className={`${styles.sectionTitle} ${styles.text_center}`}>
          <h2>
            <span
              className={`${styles.shape} ${styles.shape_left} ${styles.bg_color_4}`}
            ></span>
            <span>Nos Services</span>
            <span
              className={`${styles.shape} ${styles.shape_right} ${styles.bg_color_4}`}
            ></span>
          </h2>
        </div>

        <div
          className={styles.row}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center", // Center cards when there's less than four in a row
            gap: "20px", // Add space between cards
          }}
        >
          {services?.map((service, index) => (
            <div
              key={service.id}
              ref={(el) => (cardRefs.current[index] = el)}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
                flex: "1 1 calc(25% - 20px)", // 25% width per card, accounting for gap
                maxWidth: "calc(25% - 20px)", // Ensure the max width matches the flex basis
              }}
            >
              <div
                className={`${styles.thumbnail} ${styles.thumbnailContent}`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                }}
              >
                <Link to={`/service/${service.id}`}>
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/servicesImages/${service?.image}`}
                    alt={service.title}
                    className={styles.img_responsive}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "8px 8px 0 0",
                    }}
                  />
                </Link>
                <div
                  className={`${styles.caption} ${styles.border_color_1}`}
                  style={{
                    padding: "15px",
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <h3>
                    <a href="" className={styles.color_1}>
                      {service.title}
                    </a>
                  </h3>
                  <p>{service.short_description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ServicesSection.propTypes = propTypes;
ServicesSection.defaultProps = defaultProps;

export default ServicesSection;
