import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import ReactGA from "react-ga";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";
import Results from "./views/Results";
import AboutUs from "./views/AboutUs";
import Partners from "./views/Partners";
import NewsBlog from "./views/NewsBlog";
import Report from "./views/Report";
import News from "./views/News";
import NewsCategory from "./views/NewsCategory";

import Services from "./views/Services";
import Service from "./views/Service";
import Faqs from "./views/Faqs";
import ContactUs from "./views/ContactUs";

import "./App.css";
import Gallery from "./views/Gallery";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />

          {/* <AppRoute
            exact
            path="/faqs"
            component={Faqs}
            layout={LayoutDefault}
          /> */}

          <AppRoute
            exact
            path="/contactus"
            component={ContactUs}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/gallery"
            component={Gallery}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/results"
            component={Results}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/report/:PatientId/:ExamId"
            component={Report}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/about-us"
            component={AboutUs}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/insurance-partners"
            component={Partners}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/news"
            component={News}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/services"
            component={Services}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/service/:id"
            component={Service}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/news/:category"
            component={NewsCategory}
            layout={LayoutDefault}
          />

          <AppRoute
            exact
            path="/news/:category/:id"
            component={NewsBlog}
            layout={LayoutDefault}
          />
        </Switch>
      )}
    />
  );
};

export default App;
