import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import styles from "../../assets/styles/ServicesSection.module.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import axios from "axios";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const BranchesSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Nos Filiales",
    paragraph:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.",
  };

  const [branches, setBranches] = useState([]);
  const cardRefs = useRef([]);

  const getBranches = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/branches/get`, {
        params: {},
      })
      .then(function (response) {
        setBranches(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    // Find the tallest card and apply that height to all cards
    const getMaxHeight = () => {
      const heights = cardRefs.current.map((card) => card?.clientHeight || 0);
      const maxHeight = Math.max(...heights);
      cardRefs.current.forEach((card) => {
        card.style.height = `${maxHeight}px`; // Apply max height to all cards
      });
    };

    // Ensure the height is calculated after the component mounts
    if (branches.length > 0) {
      getMaxHeight();
    }

    // Resize listener to adjust when window resizes
    window.addEventListener("resize", getMaxHeight);
    return () => window.removeEventListener("resize", getMaxHeight);
  }, [branches]);

  return (
    <section
      className={`${styles.whiteSection} ${styles.full_width} ${styles.clearfix} ${styles.coursesSection}`}
      id="Branches"
    >
      <div className={styles.container}>
        <div className={`${styles.sectionTitle} ${styles.text_center}`}>
          <h2>
            <span
              className={`${styles.shape} ${styles.shape_left} ${styles.bg_color_4}`}
            ></span>
            <span>Nos Filiales</span>
            <span
              className={`${styles.shape} ${styles.shape_right} ${styles.bg_color_4}`}
            ></span>
          </h2>
        </div>

        {/* Flexbox row to align the cards */}
        <div
          className={styles.row}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {branches?.map((branch, index) => (
            <div
              key={branch.id}
              ref={(el) => (cardRefs.current[index] = el)}
              className={`${styles.col_sm_4} ${styles.col_xs_12} ${styles.block}`}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
                flex: "1 1 30%", // Ensure cards are responsive and fill the row
              }}
            >
              <div
                className={`${styles.thumbnail} ${styles.thumbnailContent}`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff", // Background color for the card
                  borderRadius: "8px", // Optional rounded corners for the card
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Optional shadow for styling
                  height: "100%", // Allow the card to take up full height
                  flex: "1", // Ensure the card takes available space
                }}
              >
                <Link to={`/branch/${branch.id}`}>
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/branchesImages/${branch?.image}`}
                    alt={branch.title}
                    className={styles.img_responsive}
                    style={{
                      width: "100%",
                      height: "200px", // Fixed image height for consistency
                      objectFit: "cover", // Ensure images fill the area without distortion
                      borderRadius: "8px 8px 0 0", // Rounded top corners to match the card
                    }}
                  />
                </Link>
                <div
                  className={`${styles.caption} ${styles.border_color_1}`}
                  style={{
                    padding: "15px",
                    flex: "1", // Allow the description section to take the remaining space
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start", // Align content to the top of the card
                  }}
                >
                  <h3>
                    <a href="" className={styles.color_1}>
                      {branch.title}
                    </a>
                  </h3>
                  <p>{branch.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

BranchesSection.propTypes = propTypes;
BranchesSection.defaultProps = defaultProps;

export default BranchesSection;
