import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import axios from "axios";

const Gallery = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [currentPage, setCurrentPage] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/categories/get`, {
        params: {},
      })
      .then(function (response) {
        setCategories(response.data);

        setSelectedCategory(response.data[0].id);
        setCurrentPage(0);
        getCategoryImages(response.data[0].id);

        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getCategoryImages = (id) => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/category_images/get`, {
        params: {id: id},
      })
      .then(function (response) {
        setImages(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const itemsPerPage = 4;

  // Filter images based on category
  const filteredImages = images;
  // selectedCategory === "All" ? images : images.filter((image) => image.category === selectedCategory);

  // Pagination
  const pageCount = Math.ceil(filteredImages.length / itemsPerPage);
  const displayedImages = filteredImages.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  console.log(images, displayedImages);
  const handlePageClick = ({selected}) => {
    setCurrentPage(selected);
  };

  const openModal = (src) => {
    setModalImage(src);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalImage("");
  };
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="container my-4 pt-4">
      {/* <h1 className="text-center mb-4 pt-4"> Galerie</h1> */}

      {/* Category Buttons */}
      <div className="d-flex justify-content-center mb-3 mt-3" style={{marginTop: 100}}>
        {categories.map((category) => (
          <button
            key={category}
            style={{
              margin: 5,
              padding: "2px 5px 2px 5px",
              backgroundColor: selectedCategory === category.id ? "#007bff" : "white",
              color: selectedCategory === category.id ? "white" : "#007bff",
              cursor: "pointer",
              borderColor: "#007bff",
              fontSize: 14,
              minWidth: 100,
              borderRadius: 10,
            }}
            className={`btn mx-2 ${selectedCategory === category.id ? "btn-primary" : "btn-outline-primary"}`}
            onClick={() => {
              setSelectedCategory(category.id);
              setCurrentPage(0);
              getCategoryImages(category.id);
            }}
          >
            {category.name}
          </button>
        ))}
      </div>

      {/* Gallery Grid */}
      <div className="gallery-grid pt-4" style={{marginTop: 50}}>
        {displayedImages.map((image) => (
          <div
            key={image.id}
            className="gallery-item"
            onClick={() =>
              openModal(`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/categoriesImages/${image.name}`)
            }
            style={{cursor: "pointer"}}
          >
            <img
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/categoriesImages/${image.name}`}
              alt={image.name}
              className="img-fluid rounded"
            />
          </div>
        ))}
      </div>

      {/* Pagination */}

      {/* Modal for Full-Screen View */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // maxWidth: "90%",
            width: "100%",
            maxHeight: "80vh",
            zIndex: 1000000,
          },
        }}
      >
        <img
          style={{margin: "auto", maxHeight: "80vh"}}
          src={modalImage}
          alt="Full Screen"
          className="img-fluid"
        />
        <button
          className="btn btn-danger mt-2"
          onClick={closeModal}
          style={{display: "block", margin: "auto"}}
        >
          Close
        </button>
      </Modal>

      {!modalIsOpen && (
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      )}
    </div>
  );
};

export default Gallery;
